
import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";


const get = async (headers) => {
  try {
    const requestParams = new RequestParams({}, headers);
    const { json: response } = await api.get('ixextractors', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const getById = async (extractorId, headers) => {
  try {
    const requestParams = new RequestParams({ id: extractorId }, headers);
    const { json: response } = await api.get('ixextractors', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const save = async (extractor) => {
  const requestParams = new RequestParams(extractor);
  let response;

  if (extractor._id) {
    response = await api.put('ixextractors', requestParams);
  } else {
    response = await api.post('ixextractors', requestParams);
  }

  return response;
};

const remove = async (ids) => {
  const requestParams = new RequestParams({ ids });
  const response = await api.delete('ixextractors', requestParams);
  return response;
};

export { get, save, remove, getById };