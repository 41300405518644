import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";




const getById = async (_id) => {
  try {
    const requestParams = new RequestParams({ _id });
    const { json: response } = await api.get('files', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const getByType = async (
type,
header) =>
{
  try {
    const requestParams = new RequestParams({ type }, header);
    const { json: response } = await api.get('files', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const update = async (file) => {
  try {
    const requestParams = new RequestParams(file);
    const { json: response } = await api.post('files', requestParams);
    return response;
  } catch (e) {
    return e;
  }
};

const remove = async (_id) => {
  try {
    const requestParams = new RequestParams({ _id });
    const { json: response } = await api.delete('files', requestParams);
    return response[0];
  } catch (e) {
    return e;
  }
};

export { UploadService } from "./UploadService";
export { getById, getByType, update, remove };