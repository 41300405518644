
import { cloneDeep } from 'lodash';


const getRowIds = (data) => {
  const identifiers = [];

  data.forEach((element) => {var _element$subRows, _element$subRows2;
    identifiers.push({ id: element.rowId });

    if ((_element$subRows = element.subRows) !== null && _element$subRows !== void 0 && _element$subRows.length) {
      element.subRows.forEach((subElement) => {
        identifiers.push({ id: subElement.rowId, parentId: element.rowId });
      });
    }

    if (((_element$subRows2 = element.subRows) === null || _element$subRows2 === void 0 ? void 0 : _element$subRows2.length) === 0) {
      identifiers.push({ id: `${element.rowId}-dropzone`, parentId: element.rowId });
    }
  });

  return identifiers;
};

// eslint-disable-next-line max-statements
const dndSortHandler = ({
  currentState,
  dataIds,
  activeId,
  overId,
  disableEditingGroups






}) => {var _state$find, _state$find$subRows;
  const state = cloneDeep(currentState);

  const { activeParent, overParent } = dataIds.reduce(
    (acc, { id, parentId }) => {
      if (id === activeId) acc.activeParent = parentId;
      if (id === overId) acc.overParent = parentId;
      return acc;
    },
    { activeParent: undefined, overParent: undefined }



  );

  if (disableEditingGroups && activeParent !== overParent) {
    return currentState;
  }

  const { active: activePosition = 0, dropped: droppedPosition = 0 } = state.reduce(
    // eslint-disable-next-line max-statements
    ({ active, dropped }, item, index) => {
      let droppedIndex = dropped;
      let activeIndex = active;

      if (active === undefined) {
        if (item.rowId === activeParent) {var _item$subRows;
          activeIndex = ((_item$subRows = item.subRows) === null || _item$subRows === void 0 ? void 0 : _item$subRows.findIndex((element) => element.rowId === activeId)) || 0;
        } else if (item.rowId === activeId) {
          activeIndex = index;
        }
      }

      if (dropped === undefined) {
        if (item.rowId === overParent) {var _item$subRows2;
          droppedIndex = ((_item$subRows2 = item.subRows) === null || _item$subRows2 === void 0 ? void 0 : _item$subRows2.findIndex((element) => element.rowId === overId)) || 0;
        } else if (item.rowId === overId) {
          droppedIndex = index;
        }
      }

      return {
        active: activeIndex,
        dropped: droppedIndex
      };
    },
    {
      active: undefined,
      dropped: undefined
    }



  );

  const activeElement = activeParent ? (_state$find =
  state.find((item) => item.rowId === activeParent)) === null || _state$find === void 0 ? void 0 : (_state$find$subRows = _state$find.subRows) === null || _state$find$subRows === void 0 ? void 0 : _state$find$subRows.splice(activePosition, 1)[0] :
  state.splice(activePosition || 0, 1)[0];

  if (!activeElement || Object.hasOwn(activeElement, 'subRows') && overParent) {
    return currentState;
  }

  if (overParent) {var _state$find2, _state$find2$subRows;
    (_state$find2 = state.
    find((item) => item.rowId === overParent)) === null || _state$find2 === void 0 ? void 0 : (_state$find2$subRows = _state$find2.
    subRows) === null || _state$find2$subRows === void 0 ? void 0 : _state$find2$subRows.splice(droppedPosition, 0, activeElement);
  } else {
    state.splice(droppedPosition, 0, activeElement);
  }

  return state;
};

export { getRowIds, dndSortHandler };