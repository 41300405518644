import api from "../utils/api";
import { RequestParams } from "../utils/RequestParams";



export const searchCollaborators = async (value) => {
  const response = await api.get('collaborators', new RequestParams({ filterTerm: value }));
  return response.json;
};

export const loadGrantedPermissions = async (
sharedIds) =>
{
  const response = await api.put('entities/permissions', new RequestParams({ sharedIds }));
  return response.json;
};

export const savePermissions = async (
permissionsData) =>
{
  const response = await api.post('entities/permissions', new RequestParams(permissionsData));
  return response.json;
};