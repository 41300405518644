export const COLORS = [
'#C03B22',
'#D9534F',
'#E91E63',
'#A03AB1',
'#6F46B8',
'#3F51B5',
'#2196F3',
'#37BDCf',
'#359990',
'#5CB85C',
'#8BC34A',
'#CDDC39',
'#CCBC2F',
'#F0AD4E',
'#EC9920',
'#E46841',
'#795548',
'#9E9E9E',
'#607D8B'];


export default COLORS;