function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}
// import api from 'app/utils/api';
import { RequestParams } from "../../../utils/RequestParams";





let dummyData = [
{
  _id: '1',
  templatesFrom: ['66fbe4f28542cc5545e05a46', '66fbe4d28542cc5545e0599c'],
  templateTo: '66ffac5860f7ab062d87d13e',
  documents: 831,
  generatedEntities: 12000
},
{
  _id: '2',
  templatesFrom: ['66fbe4d28542cc5545e0599c', 'Judge Documents'],
  templateTo: '66ffac5860f7ab062d87d13e',
  documents: 500,
  generatedEntities: 12001
}];


// const apiEndpoint = 'paragraph-extractor';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const get = async (headers) =>
new Promise((resolve) => {
  setTimeout(() => {
    resolve(dummyData);
  });
});

const getById = async (extractorId, headers) => {
  try {var _requestParams$data;
    const requestParams = new RequestParams({ id: extractorId }, headers);
    // const { json: response } = await api.get(apiEndpoint, requestParams);
    const id = (_requestParams$data = requestParams.data) === null || _requestParams$data === void 0 ? void 0 : _requestParams$data.id;
    return dummyData.find((datum) => datum._id === id);
    // return response;
  } catch (e) {
    return e;
  }
};

const save = async (
extractorValues) =>
{var _extractorValues$_id;
  // const requestParams = new RequestParams(extractorValues);

  const dummyEntry = _objectSpread(_objectSpread({},
  extractorValues), {}, {
    documents: Math.floor(Math.random() * 1000),
    generatedEntities: Math.floor(Math.random() * 1000),
    _id: (_extractorValues$_id = extractorValues === null || extractorValues === void 0 ? void 0 : extractorValues._id) !== null && _extractorValues$_id !== void 0 ? _extractorValues$_id : Math.floor(Math.random() * 100).toString() });


  dummyData.push(dummyEntry);
  console.log(dummyData);

  return new Promise((resolve) => {
    resolve(dummyEntry);
  });
};

const remove = async (ids) => {
  // const requestParams = new RequestParams({ ids });
  // const response = await api.delete(apiEndpoint, requestParams);
  // return response;
  dummyData = dummyData.filter((data) => {var _data$_id;return !ids.includes((_data$_id = data._id) !== null && _data$_id !== void 0 ? _data$_id : '');});
  console.log(dummyData);
  return true;
};

export { get, save, remove, getById };