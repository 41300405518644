import api from "../TemplatesAPI";
import documentsAPI from "../../Documents/DocumentsAPI";
import { actions } from "../../BasicReducer";
import { RequestParams } from "../../utils/RequestParams";

export function deleteTemplate(template) {
  return (dispatch) =>
  api.delete(new RequestParams({ _id: template._id })).then(() => {
    dispatch(actions.remove('templates', template));
  });
}

export function checkTemplateCanBeDeleted(template) {
  return () =>
  documentsAPI.
  countByTemplate(new RequestParams({ templateId: template._id })).
  then((usedForDocuments) => {
    if (usedForDocuments) {
      return Promise.reject();
    }
    return Promise.resolve();
  });
}

export function setAsDefault(template) {
  return () => api.setAsDefault(new RequestParams({ _id: template._id }));
}