import * as types from "./actionTypes";
import api from "../RelationTypesAPI";
import { actions } from "../../BasicReducer";
import { notificationActions } from "../../Notifications";
import { t } from "../../I18N";
import { RequestParams } from "../../utils/RequestParams";

export function saveRelationType(relationType) {
  return (dispatch) =>
  api.save(new RequestParams(relationType)).then((response) => {
    dispatch({ type: types.RELATION_TYPE_SAVED });
    dispatch(actions.push('relationTypes', response));
    dispatch(
      notificationActions.notify(t('System', 'RelationType saved', null, false), 'success')
    );
  });
}

export function resetRelationType() {
  return { type: types.RESET_RELATION_TYPE };
}