function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import { connect } from 'react-redux';

import { RequestParams } from "../../../utils/RequestParams";
import api from "../../../Search/SearchAPI";
import { t } from "../../../I18N";

import CejilChart from "./CejilChart";
import parsingUtils from "../utils/parsingUtils";

const judgesCommisionersTemplate = '58b2f3a35d59f31e1345b4b6';
const countryKey = 'pa_s';
const male = 'dedcc624-0e11-4d4e-90d5-d1c0ea4c7a18';
const female = 'f2457229-e142-4b74-b595-2ac2f9b5f64e';
const sexTranslationsContext = '58b2f3a35d59f31e1345b52d';

function assignFilter(filters, sex) {
  return _objectSpread(_objectSpread({}, filters), {}, { sexo: { values: [sex] } });
}

function conformSearchQuery(filters) {
  return api.search(new RequestParams({ types: [judgesCommisionersTemplate], filters, limit: 0 }));
}

function getData() {
  const filters = {};
  filters[this.props.filterProperty] = { from: -2208988800 };

  const maleFilters = assignFilter(filters, male);
  const femaleFilters = assignFilter(filters, female);

  return Promise.all([filters, maleFilters, femaleFilters].map(conformSearchQuery));
}

function prepareData(countries, setA, setB) {
  return countries.map((_country) => {
    const country = _country;
    const maleResults = parsingUtils.findBucketsByCountry(setA, countryKey, country.key);
    const femaleResults = parsingUtils.findBucketsByCountry(setB, countryKey, country.key);

    country.name = country.label;

    country.setALabel = t(sexTranslationsContext, 'Hombre', null, false);
    country.setAValue = maleResults ? maleResults.filtered.doc_count : 0;

    country.setBLabel = t(sexTranslationsContext, 'Mujer', null, false);
    country.setBValue = femaleResults ? femaleResults.filtered.doc_count : 0;

    return country;
  });
}

function mapStateToProps({ thesauris }, { filterProperty = 'mandatos_de_la_corte' }) {
  return { thesauris, getData, prepareData, filterProperty };
}

export default connect(mapStateToProps)(CejilChart);
export {
  judgesCommisionersTemplate,
  countryKey,
  male,
  female,
  sexTranslationsContext,
  mapStateToProps };