import _ from 'lodash';



function objectIndex(
dataArray,
indexingFunction,
dataTransformation)
{
  const grouped = _.groupBy(dataArray, indexingFunction);
  const transformed = Object.fromEntries(
    Object.entries(grouped).map(([key, [elem]]) => [key, dataTransformation(elem)])
  );
  return transformed;
}

function objectIndexToArrays(
dataArray,
indexingFunction,
dataTransformation)
{
  const grouped = _.groupBy(dataArray, indexingFunction);
  const transformed = Object.fromEntries(
    Object.entries(grouped).map(([key, elems]) => [key, elems.map(dataTransformation)])
  );
  return transformed;
}

function objectIndexToSets(
dataArray,
indexingFunction,
dataTransformation)
{
  const grouped = _.groupBy(dataArray, indexingFunction);
  const transformed = Object.fromEntries(
    Object.entries(grouped).map(([key, elems]) => [key, new Set(elems.map(dataTransformation))])
  );
  return transformed;
}

objectIndex.NoTransform = (elem) => elem;

export { objectIndex, objectIndexToArrays, objectIndexToSets };