const objectPath = (path, object) =>
path.split('.').reduce((o, key) => {
  if (!o || !key) {
    return o;
  }
  return o.toJS ? o.get(key) : o[key];
}, object);

const logError = (err, propValueOf, propLabelOf) => {
  /* eslint-disable no-console */
  console.error('Error on EntityData: ');
  console.error('value-of: ', propValueOf, '; label-of: ', propLabelOf);
  console.error(err);
  /* eslint-enable no-console */
};

const customVisualizationTags = [
'vimeo',
'youtube',
'media',
'markdownlink',
'markdownmedia',
'counter',
'entitylink',
'itemlist',
'repeat',
'context',
'map',
'value',
'cejilchart',
'cejilchart001',
'cejilchart002',
'cejilhero',
'placeholder'];

const customExtendedTags = [
'searchbox',
'contactform',
'paypaldonatelink',
'publicform',
'searchbox',
'entityinfo',
'query',
'dataset',
'entitydata',
'entitysection',
'piechart',
'pie',
'piechart',
'pie',
'barchart',
'bar',
'tooltip',
'stackeddualbarchart',
'librarychart',
'librarycharts',
'coloredbar',
'extendedtooltip'];


const extendedValidHtmlTags = [
'blink',
'body',
'button',
'canvas',
'embed',
'element',
'fieldset',
'font',
'foreignObject',
'form',
'iframe',
'input',
'image',
'legend',
'marker',
'marquee',
'object',
'option',
'select',
'spacer',
'style',
'textarea',
'title',
'use',
'var',
'view',
'wbr'].
concat(customExtendedTags);

const visualizationHtmlTags = [
'a',
'abbr',
'acronym',
'address',
'area',
'article',
'aside',
'audio',
'b',
'bdi',
'bdo',
'big',
'blockquote',
'br',
'caption',
'center',
'circle',
'cite',
'clipPath',
'code',
'col',
'colgroup',
'content',
'data',
'datalist',
'dd',
'decorator',
'defs',
'del',
'desc',
'details',
'dfn',
'dir',
'div',
'dl',
'dt',
'ellipse',
'em',
'feBlend',
'feColorMatrix',
'feComponentTransfer',
'feComposite',
'feConvolveMatrix',
'feDiffuseLighting',
'feDisplacementMap',
'feDistantLight',
'feFlood',
'feFuncA',
'feFuncB',
'feFuncG',
'feFuncR',
'feGaussianBlur',
'feImage',
'feMerge',
'feMergeNode',
'feMorphology',
'feOffset',
'fePointLight',
'feSpecularLighting',
'feSpotLight',
'feTile',
'feTurbulence',
'figcaption',
'figure',
'filter',
'footer',
'g',
'h1',
'h2',
'h3',
'h4',
'h5',
'h6',
'head',
'header',
'hgroup',
'hr',
'html',
'i',
'img',
'ins',
'kbd',
'label',
'li',
'line',
'linearGradient',
'main',
'map',
'mark',
'mask',
'menu',
'menuitem',
'metadata',
'meter',
'nav',
'nobr',
'ol',
'optgroup',
'output',
'p',
'path',
'pattern',
'polygon',
'polyline',
'pre',
'progress',
'q',
'radialGradient',
'rect',
'rp',
'rt',
'ruby',
's',
'samp',
'section',
'shadow',
'small',
'source',
'span',
'strike',
'strong',
'sub',
'summary',
'sup',
'svg',
'switch',
'symbol',
'table',
'tbody',
'td',
'template',
'tfoot',
'th',
'thead',
'time',
'tr',
'track',
'tspan',
'tt',
'u',
'ul',
'video',
'wbr'].
concat(customVisualizationTags);

const extendedHtmlTags = visualizationHtmlTags.
concat(extendedValidHtmlTags).
concat(customExtendedTags);

export { objectPath, logError, extendedHtmlTags, visualizationHtmlTags };