function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);} /* eslint-disable max-statements */
import { isString } from 'lodash';
import uniqueID from "../../../shared/uniqueID";

const prepareFiles = async (mediaProperties, values) => {
  const metadataFiles = {};
  const entityAttachments = [];
  const files = [];

  if (values.metadata) {
    await Promise.all(
      mediaProperties.map(async (p) => {
        if (!values.metadata[p.name] || /^https?:\/\//.test(values.metadata[p.name])) {
          return Promise.resolve();
        }
        const { data, originalFile } = values.metadata[p.name];
        if (originalFile) {
          const validBlobUrlRegExp =
          /^\(?(blob:https?:\/\/(?:www\.)?[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|])(, ({.+}))?/;

          const [, url,, timeLinks] = data.match(validBlobUrlRegExp) || ['', data];
          const blob = await fetch(url).then((r) => r.blob());
          const file = new File([blob], originalFile.name, { type: blob.type });
          const fileID = uniqueID();

          metadataFiles[p.name] = fileID;

          entityAttachments.push({
            originalname: file.name,
            filename: file.name,
            type: 'attachment',
            mimetype: blob.type,
            fileLocalID: fileID,
            timeLinks
          });

          files.push(file);
        }
      })
    );
  }

  return { metadataFiles, entityAttachments, files };
};

function wrapEntityMetadata(entity, template) {var _template$properties;
  const mediaProperties =
  ((_template$properties = template.properties) === null || _template$properties === void 0 ? void 0 : _template$properties.filter((prop) => prop.type === 'image' || prop.type === 'media')) || [];

  if (!entity.metadata) {
    return _objectSpread({}, entity);
  }
  const newFileMetadataValues = (entity.attachments || []).
  filter((attachment) => attachment.fileLocalID).
  reduce(
    (previousValue, attachment, index) => _objectSpread(_objectSpread({},
    previousValue), {}, {
      [attachment.fileLocalID]: { value: '', attachment: index, timeLinks: attachment.timeLinks } }),

    {}
  );

  const metadata = Object.keys(entity.metadata).reduce((wrappedMo, key) => {var _entity$metadata$key, _entity$metadata$key2;
    let timeLinks;
    const property = mediaProperties.find((p) => p.name === key);
    const fieldValue = ((_entity$metadata$key = entity.metadata[key]) === null || _entity$metadata$key === void 0 ? void 0 : _entity$metadata$key.data) || entity.metadata[key];
    let fileLocalID = fieldValue;
    if (property && entity.metadata[key] && property.type === 'media') {
      const uniqueIdTimeLinksExp = /^\(?([\w+]{5,15})(, ({.+})\))?|$/;
      const mediaExpGroups = fieldValue.match(uniqueIdTimeLinksExp);
      if (isString(fieldValue) && mediaExpGroups && mediaExpGroups[1]) {
        [, fileLocalID = fieldValue,, timeLinks] = mediaExpGroups || [];
      }
      if (fileLocalID && fileLocalID.length < 20 && timeLinks) {
        newFileMetadataValues[fileLocalID] = _objectSpread(_objectSpread({}, newFileMetadataValues[fileLocalID]), {}, { timeLinks });
      }
    }

    const metadataValue = newFileMetadataValues[fileLocalID];
    return _objectSpread(_objectSpread({},
    wrappedMo), {}, {
      [key]: Array.isArray(entity.metadata[key]) ?
      entity.metadata[key].map((v) => ({ value: v })) :
      [metadataValue || { value: ((_entity$metadata$key2 = entity.metadata[key]) === null || _entity$metadata$key2 === void 0 ? void 0 : _entity$metadata$key2.data) || entity.metadata[key] }] });

  }, {});
  // suggestedMetadata is always in metadata-object form.
  return _objectSpread(_objectSpread({}, entity), {}, { metadata });
}

const prepareMetadataAndFiles = async (values, attachedFiles, template, mediaProperties) => {
  const { metadataFiles, entityAttachments, files } = await prepareFiles(mediaProperties, values);
  const fields = _objectSpread(_objectSpread({}, values.metadata), metadataFiles);
  const entity = _objectSpread(_objectSpread({}, values), {}, { metadata: fields, attachments: entityAttachments });
  const wrappedEntity = wrapEntityMetadata(entity, template);
  wrappedEntity.file = values.file ? values.file[0] : undefined;
  wrappedEntity.attachments = [];
  wrappedEntity.attachments.push(...files);
  wrappedEntity.attachments.push(...attachedFiles);
  return _objectSpread(_objectSpread({}, wrappedEntity), {}, { template: template._id });
};

export { prepareMetadataAndFiles, wrapEntityMetadata };