import { useEffect } from 'react';

export function useOnClickOutsideElement(
ref,
cb)
{
  useEffect(() => {
    const onClickHandler = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      cb(event);
    };

    document.addEventListener('click', onClickHandler);
    return () => {
      document.removeEventListener('click', onClickHandler);
    };
  }, [ref, cb]);
}