import { combineReducers } from 'redux';
import { modelReducer, formReducer } from 'react-redux-form';
import createReducer from "../../BasicReducer";

import prioritySortingCriteria from "../../utils/prioritySortingCriteria";

export default combineReducers({
  entityId: createReducer('connectionsList/entityId', ''),
  entity: createReducer('connectionsList/entity', {}),
  connectionsGroups: createReducer('connectionsList/connectionsGroups', []),
  searchResults: createReducer('connectionsList/searchResults', { totalRows: 0, rows: [] }),
  sort: modelReducer('connectionsList.sort', prioritySortingCriteria.get()),
  filters: createReducer('connectionsList/filters', {}),
  search: formReducer('connectionsList/search'),
  view: createReducer('connectionsList/view', 'graph')
});