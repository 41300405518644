function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}

import superagent from 'superagent';
import { actions } from "../../BasicReducer";
import { notify } from "../../Notifications/actions/notificationsActions";
import { t } from "../../I18N";





import { processFilters } from "./libraryActions";


export function triggerLocalDownload(content, fileName) {
  const url = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function clearState(dispatch) {
  dispatch(actions.set('exportSearchResultsProcessing', false));
  dispatch(actions.set('exportSearchResultsContent', ''));
  dispatch(actions.set('exportSearchResultsFileName', ''));
}

export function exportEnd() {
  return (dispatch, getState) => {
    const { exportSearchResultsContent, exportSearchResultsFileName } =
    getState().exportSearchResults;

    triggerLocalDownload(exportSearchResultsContent, exportSearchResultsFileName);

    clearState(dispatch);
  };
}

function extractFileName(contentDisposition) {
  const startIndex = contentDisposition.indexOf('filename="') + 10;
  const endIndex = contentDisposition.length - 1;
  return contentDisposition.substring(startIndex, endIndex);
}

const requestHandler = (
_params,
dispatch,
captcha) =>
{
  const params = _objectSpread({}, _params);
  if (params.ids) params.ids = params.ids.toJS();
  let request = superagent.
  post('/api/export').
  send(params).
  set('Accept', 'text/csv').
  set('X-Requested-With', 'XMLHttpRequest');

  if (captcha) {
    request = request.set('Captcha-text', captcha.text).set('Captcha-id', captcha.id);
  }

  request.
  then((response) => {
    const fileName = extractFileName(response.header['content-disposition']);
    dispatch(actions.set('exportSearchResultsContent', response.text));
    dispatch(actions.set('exportSearchResultsFileName', fileName));
    dispatch(exportEnd());
  }).
  catch((err) => {
    clearState(dispatch);
    if (err.status === 403) {
      dispatch(notify(t('System', 'Invalid captcha'), 'danger'));
    } else {
      dispatch(notify(t('System', 'An error has occurred during data export'), 'danger'));
    }
    return err;
  });
};

export function exportDocuments(storeKey, captcha) {
  return async (dispatch, getState) => {
    const state = getState().library;
    const { search, filters } = state;
    const exportFilters = filters.toJS();

    const finalSearchParams = processFilters(search, exportFilters, {
      limit: 10000,
      encoding: false
    });

    finalSearchParams.searchTerm = state.search.searchTerm;

    if (state.ui.get('selectedDocuments').size) {
      finalSearchParams.ids = state.ui.
      get('selectedDocuments').
      map((entity) => entity.get('sharedId'));
    }

    if (storeKey === 'uploads') finalSearchParams.unpublished = true;

    dispatch(actions.set('exportSearchResultsProcessing', true));

    requestHandler(finalSearchParams, dispatch, captcha);
  };
}