export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const ADD_DOCUMENTS = 'ADD_DOCUMENTS';
export const UNSET_DOCUMENTS = 'UNSET_DOCUMENTS';
export const UPDATE_DOCUMENT = 'LIBRARY/UPDATE_DOCUMENT';
export const UPDATE_DOCUMENTS = 'LIBRARY/UPDATE_DOCUMENTS';
export const UPDATE_DOCUMENTS_PUBLISHED = 'UPDATE_DOCUMENTS_PUBLISHED';
export const SET_LIBRARY_TEMPLATES = 'SET_LIBRARY_TEMPLATES';
export const ELEMENT_CREATED = 'ELEMENT_CREATED';
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const REMOVE_DOCUMENTS = 'REMOVE_DOCUMENTS';
export const REMOVE_DOCUMENTS_SHAREDIDS = 'REMOVE_DOCUMENTS_SHAREDIDS';
export const UPDATE_SELECTED_ENTITIES = 'UPDATE_SELECTED_ENTITIES';
//Search
export const SET_SEARCHTERM = 'SET_SEARCHTERM';
export const SET_PREVIEW_DOC = 'SET_PREVIEW_DOC';
export const SET_SUGGESTIONS = 'SET_SUGGESTIONS';
export const SHOW_SUGGESTIONS = 'SHOW_SUGGESTIONS';
export const HIDE_SUGGESTIONS = 'HIDE_SUGGESTIONS';
export const OVER_SUGGESTIONS = 'OVER_SUGGESTIONS';
//Filters
export const SET_LIBRARY_FILTERS = 'SET_LIBRARY_FILTERS';
export const UPDATE_LIBRARY_FILTERS = 'UPDATE_LIBRARY_FILTERS';
export const INITIALIZE_FILTERS_FORM = 'INITIALIZE_FILTERS_FORM';
//ui
export const SELECT_DOCUMENT = 'SELECT_DOCUMENT';
export const SELECT_SINGLE_DOCUMENT = 'SELECT_SINGLE_DOCUMENT';
export const SELECT_DOCUMENTS = 'SELECT_DOCUMENTS';
export const UNSELECT_DOCUMENT = 'UNSELECT_DOCUMENT';
export const UNSELECT_ALL_DOCUMENTS = 'UNSELECT_ALL_DOCUMENTS';
export const SHOW_FILTERS = 'SHOW_FILTERS';
export const HIDE_FILTERS = 'HIDE_FILTERS';
export const ENTER_LIBRARY = 'ENTER_LIBRARY';
export const ZOOM_IN = 'ZOOM_IN';
export const ZOOM_OUT = 'ZOOM_OUT';
export const SET_TABLE_VIEW_COLUMNS = 'SET_TABLE_VIEW_COLUMNS';
export const SET_TABLE_VIEW_COLUMN_HIDDEN = 'SET_TABLE_VIEW_COLUMN_HIDDEN';
export const SET_TABLE_VIEW_ALL_COLUMNS_HIDDEN = 'SET_TABLE_VIEW_ALL_COLUMNS_HIDDEN';