function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import * as types from "./actionTypes";
import libraryHelper from "../helpers/libraryFilters";
import comonPropertiesHelper from "../../../shared/commonProperties";
import * as libraryActions from "./libraryActions";
import prioritySortingCriteria from "../../utils/prioritySortingCriteria";
import { actions as formActions } from 'react-redux-form';

export function filterDocumentTypes(documentTypes, location, navigate) {
  return (dispatch, getState) => {
    const state = getState();

    const templates = state.templates.toJS();
    const thesauris = state.thesauris.toJS();

    let libraryFilters = comonPropertiesHelper.
    comonProperties(templates, documentTypes).
    filter((prop) => prop.filter);
    libraryFilters = libraryHelper.populateOptions(libraryFilters, thesauris);

    const usefulTemplates = documentTypes.length ?
    templates.filter((t) => documentTypes.includes(t._id)) :
    templates;

    const { sort, order } = prioritySortingCriteria.get({
      currentCriteria: { sort: state.library.search.sort, order: state.library.search.order },
      filteredTemplates: usefulTemplates.map((t) => t._id),
      templates: state.templates,
      selectedSorting: state.library.selectedSorting
    });

    const search = _objectSpread(_objectSpread({
      types: documentTypes },
    state.library.search), {}, {
      sort,
      order });


    const filters = { documentTypes, properties: libraryFilters };
    dispatch(libraryActions.searchDocuments({ filters, search, location, navigate }));
  };
}

export function resetFilters(navigate, location) {
  return (dispatch, getState) => {
    dispatch({ type: types.SET_LIBRARY_FILTERS, documentTypes: [], libraryFilters: [] });
    dispatch(
      formActions.load('library.search', {
        searchTerm: '',
        filters: {},
        order: 'desc',
        sort: 'creationDate'
      })
    );
    libraryActions.searchDocuments({ navigate, location })(dispatch, getState);
  };
}

export function toggleFilter(propertyName, properties) {
  return (dispatch) => {
    const updatedProperties = properties.map((property) => {
      if (property.name === propertyName) {
        property.active = !property.active;
      }
      return property;
    });
    dispatch({ type: types.UPDATE_LIBRARY_FILTERS, libraryFilters: updatedProperties });
  };
}