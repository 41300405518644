
import { SettingsAPI } from "../../../Settings";
import { RequestParams } from "../../../utils/RequestParams";


import api from "../../../utils/api";

const get = async (headers) => {
  const requestParams = new RequestParams({}, headers);
  return SettingsAPI.get(requestParams);
};

const save = async (
settings,
headers) =>
{
  const requestParams = new RequestParams(settings, headers);
  return SettingsAPI.save(requestParams);
};

const getLinks = async (headers) => {
  const requestParams = new RequestParams({}, headers);
  return api.get('settings/links', requestParams).then((response) => response.json);
};

const saveLinks = async (links, headers) => {
  const requestParams = new RequestParams(links, headers);
  return api.post('settings/links', requestParams).then((response) => response.json);
};

const getStats = async (headers) => {
  const requestParams = new RequestParams({}, headers);
  return api.get('stats', requestParams).then((response) => response.json);
};

export { get, save, getLinks, saveLinks, getStats };