export default [
'#0088FE',
'#00ABC4',
'#00C49F',
'#A8C400',
'#FFBB28',
'#FF8042',
'#D24040',
'#A250B3'];


const light = [
'#7fc3fe',
'#7fd4e1',
'#7fe1ce',
'#d3e17f',
'#ffdc93',
'#ffbfa0',
'#e89f9f',
'#d0a7d8'];


export { light };