

const propertyTypes = {
  date: 'date',
  daterange: 'daterange',
  geolocation: 'geolocation',
  image: 'image',
  link: 'link',
  markdown: 'markdown',
  media: 'media',
  multidate: 'multidate',
  multidaterange: 'multidaterange',
  multiselect: 'multiselect',
  nested: 'nested',
  numeric: 'numeric',
  preview: 'preview',
  relationship: 'relationship',
  select: 'select',
  text: 'text',
  generatedid: 'generatedid',
  newRelationship: 'newRelationship'
};

function getCompatibleTypes(type) {
  switch (type) {
    case propertyTypes.date:
    case propertyTypes.multidate:
      return [propertyTypes.date, propertyTypes.multidate];
    case propertyTypes.daterange:
    case propertyTypes.multidaterange:
      return [propertyTypes.daterange, propertyTypes.multidaterange];
    case propertyTypes.select:
    case propertyTypes.multiselect:
      return [propertyTypes.select, propertyTypes.multiselect];
    case propertyTypes.text:
    case propertyTypes.markdown:
      return [propertyTypes.text, propertyTypes.markdown];

    default:
      return [type];
  }
}

const propertyIsSelect = (propertyType) => propertyType === 'select';

const propertyIsMultiselect = (propertyType) =>
propertyType === 'multiselect';

const propertyIsSelectOrMultiSelect = (propertyType) =>
propertyIsSelect(propertyType) || propertyIsMultiselect(propertyType);

const propertyIsRelationship = (propertyType) => propertyType === 'relationship';

export {
  propertyTypes,
  getCompatibleTypes,
  propertyIsSelect,
  propertyIsMultiselect,
  propertyIsSelectOrMultiSelect,
  propertyIsRelationship };