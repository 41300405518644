/* eslint-disable import/no-mutable-exports,prefer-destructuring,global-require */

import { isClient } from "./utils";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { applyMiddleware, createStore } from 'redux';
import reducer from "./reducer";


// eslint-disable-next-line @typescript-eslint/no-unused-vars







const data = isClient && window.__reduxData__ ? window.__reduxData__ : {};
let store;

export default function create(initialData = data) {
  store = createStore(reducer, initialData, composeWithDevTools(applyMiddleware(thunk)));

  return store;
}

if (module.hot) {
  if (!window.store) {
    window.store = create();
  }
  store = window.store;
  module.hot.accept('./reducer', () => {
    const rootReducer = require("./reducer");
    store.replaceReducer(rootReducer);
  });
}

if (!store) {
  store = create();
}

if (typeof window !== 'undefined' && !module.hot) {
  window.store = store;
}

export { store };