
// import api from 'app/utils/api';
import { RequestParams } from "../../../utils/RequestParams";


const dummyData = [
{
  _id: '1',
  title: 'John Smith',
  document: 'doc_name.pdf',
  languages: ['en', 'es'],
  templateId: '66fbe4f28542cc5545e05a46',
  paragraphCount: 3
},
{
  _id: '2',
  title: 'Maria Garcia',
  document: 'another doc.pdf',
  languages: ['en', 'es'],
  templateId: '66fbe4f28542cc5545e05a46',
  paragraphCount: 5
},
{
  _id: '3',
  title: 'Pierre Dubois',
  document: 'third_doc.pdf',
  languages: ['en', 'es'],
  templateId: '66fbe4f28542cc5545e05a46',
  paragraphCount: 2
},
{
  _id: '4',
  title: 'Hans Mueller',
  document: 'fourth_doc.pdf',
  languages: ['en', 'es'],
  templateId: '66fbe4f28542cc5545e05a46',
  paragraphCount: 4
},
{
  _id: '5',
  title: 'Giulia Rossi',
  document: 'fifth_doc.pdf',
  languages: ['en', 'es'],
  templateId: '66fbe4f28542cc5545e05a46',
  paragraphCount: 6
}];


// const apiEndpoint = 'paragraph-extractor-entities';

const getByParagraphExtractorId = async (extractorId, headers) => {
  try {var _requestParams$data;
    const requestParams = new RequestParams({ id: extractorId }, headers);
    // const { json: response } = await api.get(apiEndpoint, requestParams);
    const id = (_requestParams$data = requestParams.data) === null || _requestParams$data === void 0 ? void 0 : _requestParams$data.id;
    return dummyData || id;
    // return response;
  } catch (e) {
    return e;
  }
};

const getById = async (entityId, headers) => {
  try {var _requestParams$data2;
    const requestParams = new RequestParams({ id: entityId }, headers);
    // const { json: response } = await api.get(apiEndpoint, requestParams);
    const id = (_requestParams$data2 = requestParams.data) === null || _requestParams$data2 === void 0 ? void 0 : _requestParams$data2.id;
    return dummyData.find((entity) => entity._id === id);
    // return response;
  } catch (e) {
    return e;
  }
};

export { getByParagraphExtractorId, getById };