/* eslint-disable react/no-multi-comp */
import sift from 'sift';







// eslint-disable-next-line max-statements
const Section = ({ data, children, showIf: condition }) => {
  const filtered = data.filter(sift(condition));
  return filtered.length > 0 ? children : null;
};

export { Section };