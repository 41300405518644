
import { language } from "./languagesList";

export const entityDefaultDocument = (
entityDocuments,
entityLanguage,
defaultLanguage) =>
{
  const documents = entityDocuments || [];
  const documentMatchingEntity = documents.find(
    (document) =>
    document.language && language(document.language, 'ISO639_1') === entityLanguage
  );

  const documentMatchingDefault = documents.find(
    (document) =>
    document.language && language(document.language, 'ISO639_1') === defaultLanguage
  );

  return documentMatchingEntity || documentMatchingDefault || documents[0];
};