
import { t } from "../../I18N";


const shouldDisplayTranslation = (
name,
contexId,
locale,
availableLanguages) =>
{
  const defaultLanguageKey = availableLanguages.
  find((language) => (language === null || language === void 0 ? void 0 : language.get('default')) === true).
  get('key');
  const isDefaultLanguage = locale === defaultLanguageKey;
  const translatedTemplateName = t(contexId, name, undefined, false);

  switch (true) {
    case isDefaultLanguage && translatedTemplateName !== name:
      return true;

    case !isDefaultLanguage:
      return true;

    default:
      return false;
  }
};

export { shouldDisplayTranslation };