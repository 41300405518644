function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import superagent from 'superagent';
import { APIURL } from "../../../config";





class UploadService {














  constructor(endpoint) {_defineProperty(this, "requests", []);_defineProperty(this, "aborted", false);_defineProperty(this, "onProgressCallback", void 0);_defineProperty(this, "onUploadCompleteCallback", void 0);_defineProperty(this, "filesQueue", []);_defineProperty(this, "route", void 0);
    this.route = `${APIURL}files/upload/${endpoint}`;
  }

  // eslint-disable-next-line max-statements
  async uploadQueue(files, responses) {
    if (this.aborted) {
      this.aborted = false;
      return;
    }

    if (files.length === 0) return;

    const file = files.shift();

    const request = superagent.
    post(this.route).
    set('Accept', 'application/json').
    set('X-Requested-With', 'XMLHttpRequest').
    field('originalname', file.name).
    attach('file', file).
    on('progress', (event) => {
      if (this.onProgressCallback && event.percent) {
        this.onProgressCallback(file.name, Math.floor(event.percent), event.total);
      }
    });

    this.requests.push(request);

    try {
      const response = await request;
      responses.push(response.body);
      if (this.onUploadCompleteCallback) {
        this.onUploadCompleteCallback(response.body);
      }
    } catch (error) {
      responses.push(error);
      if (this.onUploadCompleteCallback) {
        this.onUploadCompleteCallback(error);
      }
    }

    await this.uploadQueue(files, responses);
  }

  onUploadComplete(callback) {
    this.onUploadCompleteCallback = callback;
  }

  onProgress(callback) {
    this.onProgressCallback = callback;
  }

  async upload(files) {
    this.filesQueue.push(...files);
    if (this.requests.length === 0) {
      const responses = [];
      await this.uploadQueue(this.filesQueue, responses);
      this.requests = [];
      return responses;
    }
    return [];
  }

  getFilesInQueue() {
    return this.filesQueue;
  }

  abort() {
    this.aborted = true;
    this.requests.forEach((request) => request.abort());
  }

  isUploading() {
    return Boolean(this.requests.length);
  }
}

export { UploadService };