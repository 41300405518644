import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";

import { httpRequest } from "../../../../shared/superagent";


export default {
  getThesauri(params, headers) {
    const url = 'dictionaries';
    const requestParams = new RequestParams(params, headers);
    return api.get(url, requestParams).then((response) => response.json.rows);
  },

  save(thesaurus) {
    const requestParams = new RequestParams(thesaurus);
    return api.post('thesauris', requestParams).then((response) => response.json);
  },

  delete(params) {
    const requestParams = new RequestParams(params);
    return api.delete('thesauris', requestParams).then((response) => response.json);
  },

  async importThesaurus(thesaurus, file) {
    const headers = {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    };
    const fields = {
      thesauri: JSON.stringify(thesaurus)
    };
    return httpRequest('thesauris', fields, headers, file);
  }
};