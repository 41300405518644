function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}
import { actions as formActions } from 'react-redux-form';

import entitiesAPI from "../../Entities/EntitiesAPI";
import { actions } from "../../BasicReducer";
import { notificationActions } from "../../Notifications";
import { t } from "../../I18N";
import { RequestParams } from "../../utils/RequestParams";


const getAndUpdateCoercedValue = async (params, model) => {
  const { value: coercedValue, success } = await entitiesAPI.coerceValue(params);
  if (!success) {
    return notificationActions.notify(
      t('System', 'Value cannot be transformed to the correct type', null, false),
      'danger'
    );
  }
  return formActions.change(model, coercedValue);
};

const updateSelection = (
selection,
fieldName,
fieldId) =>
{
  const selected = _objectSpread({}, selection);

  const data = _objectSpread(_objectSpread({},
  fieldId && { propertyID: fieldId }), {}, {
    name: fieldName,
    timestamp: Date(),
    selection: selected });


  return actions.updateIn('documentViewer.metadataExtraction', ['selections'], data, 'propertyID');
};

const deleteSelection =
(entityDocument, propertyName, propertyID) =>
(dispatch) => {var _document$extractedMe;
  const document = entityDocument === null || entityDocument === void 0 ? void 0 : entityDocument.toJS();

  const updatedSelections = document === null || document === void 0 ? void 0 : (_document$extractedMe = document.extractedMetadata) === null || _document$extractedMe === void 0 ? void 0 : _document$extractedMe.filter(
    (selection) =>
    propertyName === 'title' && selection.name !== 'title' ||
    selection.propertyID !== propertyID
  );

  const data = _objectSpread(_objectSpread({},
  propertyID && { propertyID }), {}, {
    name: propertyName,
    selection: { text: '', selectionRectangles: [] },
    deleteSelection: true });


  return [
  dispatch(
    actions.setIn('viewer/doc', 'defaultDoc', _objectSpread(_objectSpread({},
    document), {}, {
      extractedMetadata: updatedSelections })
    )
  ),
  dispatch(
    actions.updateIn('documentViewer.metadataExtraction', ['selections'], data, 'propertyID')
  )];

};

const updateFormField = async (
value,
model,
fieldType,
locale) =>
{
  if (fieldType === 'date') {
    const requestParams = new RequestParams({
      locale,
      value,
      type: 'date'
    });
    return getAndUpdateCoercedValue(requestParams, model);
  }

  if (fieldType === 'numeric') {
    const requestParams = new RequestParams({
      locale,
      value: value.trim(),
      type: 'numeric'
    });
    return getAndUpdateCoercedValue(requestParams, model);
  }

  const requestParams = new RequestParams({
    locale,
    value,
    type: 'text'
  });
  return getAndUpdateCoercedValue(requestParams, model);
};

export { updateSelection, updateFormField, deleteSelection };