import { compareStringLists } from "./stringUtils";

const sortByStrings = (arr, stringIndices) => {
  const compare = (a, b) => {
    const aList = stringIndices.map((index) => index(a));
    const bList = stringIndices.map((index) => index(b));
    return compareStringLists(aList, bList);
  };

  return arr.sort(compare);
};

export { sortByStrings };