import { actions as formActions } from 'react-redux-form';
import { t } from "../../I18N";
import * as types from "./actionTypes";
import api from "../ThesauriAPI";
import * as notifications from "../../Notifications/actions/notificationsActions";
import { RequestParams } from "../../utils/RequestParams";

export function saveThesaurus(thesaurus) {
  return (dispatch) =>
  api.save(new RequestParams(thesaurus)).then((_thesauri) => {
    dispatch({ type: types.THESAURI_SAVED });
    notifications.notify(t('System', 'Thesaurus saved', null, false), 'success')(dispatch);
    dispatch(formActions.change('thesauri.data', _thesauri));
  });
}