function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import { actions as formActions, getModel } from 'react-redux-form';

import { advancedSort } from "../../utils/advancedSort";
import { api } from "../../Entities";
import { notificationActions } from "../../Notifications";
import { t } from "../../I18N";
import { removeDocuments, unselectAllDocuments } from "../../Library/actions/libraryActions";
import { reloadThesauri } from "../../Thesauri/actions/thesaurisActions";
import { RequestParams } from "../../utils/RequestParams";
import searchAPI from "../../Search/SearchAPI";
import { actions } from "../../BasicReducer";
import { generateID } from "../../../shared/IDGenerator";
import emptyTemplate from "../helpers/defaultTemplate";

function resetReduxForm(form) {
  return formActions.reset(form);
}

const propertyExists = (property, previousTemplate) =>
previousTemplate &&
Boolean(
  previousTemplate.properties.find(
    (p) => p.name === property.name && p.type === property.type && p.content === property.content
  )
);

const defaultValueByType = (type, options) => {
  switch (type) {
    case 'daterange':
      return { from: null, to: null };
    case 'generatedid':
      return !options.resetExisting ? generateID(3, 4, 4) : undefined;
    case 'multiselect':
    case 'relationship':
    case 'newRelationship':
    case 'nested':
    case 'multidate':
    case 'multidaterange':
      return [];
    default:
      if (!['date', 'geolocation', 'link'].includes(type)) {
        return '';
      }
      return undefined;
  }
};

const resetMetadata = (metadata, template, options, previousTemplate) => {
  const resetedMetadata = {};
  template.properties.forEach((property) => {
    const resetValue =
    options.resetExisting ||
    !propertyExists(property, previousTemplate) ||
    !metadata[property.name];

    const { type, name } = property;
    if (!resetValue) {
      resetedMetadata[property.name] = metadata[property.name];
    }
    if (resetValue) {
      const defaultValue = defaultValueByType(type, options);
      if (defaultValue !== undefined) resetedMetadata[name] = defaultValue;
    }
  });
  return resetedMetadata;
};

const getPropertyValue = (property, metadataProperty) => {
  switch (property.type) {
    case 'multiselect':
    case 'multidaterange':
    case 'nested':
    case 'relationship':
    case 'newRelationship':
    case 'multidate':
    case 'geolocation':
      return metadataProperty.map((v) => v.value);
    case 'generatedid':
      return typeof metadataProperty === 'string' ? metadataProperty : metadataProperty[0].value;
    default:
      return metadataProperty[0].value;
  }
};

const UnwrapMetadataObject = (MetadataObject, Template) =>
Object.keys(MetadataObject).reduce((UnwrapedMO, key) => {
  if (!MetadataObject[key].length) {
    return UnwrapedMO;
  }
  const property = Template.properties.find((p) => p.name === key);
  const propertyValue = getPropertyValue(property, MetadataObject[key]);
  return _objectSpread(_objectSpread({}, UnwrapedMO), {}, { [key]: propertyValue });
}, {});

function checkGeneratedTitle(entity, template) {
  const generatedTitle =
  !entity.title &&
  template.commonProperties.find((property) => property.name === 'title' && property.generatedId);
  if (generatedTitle) {
    return generateID(3, 4, 4);
  }
  return entity.title;
}

export function loadFetchedInReduxForm(form, entity, templates) {
  const sortedTemplates = advancedSort(templates, { property: 'name' });
  const defaultTemplate =
  sortedTemplates.find((sortedTemplate) => sortedTemplate.default) || sortedTemplates[0];
  const templateId = entity.template || defaultTemplate._id;
  const template =
  sortedTemplates.find((sortedTemplate) => sortedTemplate._id === templateId) || emptyTemplate;
  const title = checkGeneratedTitle(entity, template);

  const entitySelectedOptions = {};
  template.properties.forEach((property) => {
    if (property.type === 'relationship' || property.type === 'newRelationship') {
      entitySelectedOptions[property.name] = entity.metadata ? entity.metadata[property.name] : [];
    }
  });

  const metadata = UnwrapMetadataObject(
    resetMetadata(_objectSpread({}, entity.metadata), template, { resetExisting: false }, template),
    template
  );

  // suggestedMetadata remains in metadata-object form (all components consuming it are new).
  return [
  formActions.reset(form),
  formActions.load(form, _objectSpread(_objectSpread({}, entity), {}, { metadata, template: templateId, title })),
  formActions.setPristine(form),
  actions.set('entityThesauris', entitySelectedOptions)];

}

export function loadInReduxForm(form, _entity, templates) {
  return (dispatch) => {
    (_entity.sharedId ?
    api.get(new RequestParams({ sharedId: _entity.sharedId })) :
    Promise.resolve([_entity])).
    then(([response]) => {
      const { attachments } = response;
      const sortedAttachments = attachments ?
      advancedSort(attachments, { property: 'originalname' }) :
      attachments;
      const entity = _objectSpread(_objectSpread({}, response), {}, { attachments: sortedAttachments });
      loadFetchedInReduxForm(form, entity, templates).forEach((action) => dispatch(action));
      dispatch(reloadThesauri());
    });
  };
}

export function changeTemplate(form, templateId) {
  return (dispatch, getState) => {
    const entity = _objectSpread({}, getModel(getState(), form));
    const { templates } = getState();
    const template = templates.find((temp) => temp.get('_id') === templateId);
    const previousTemplate = templates.find((temp) => temp.get('_id') === entity.template);

    const templateJS = template.toJS();
    const title = checkGeneratedTitle(entity, templateJS);

    entity.metadata = resetMetadata(
      entity.metadata,
      templateJS,
      { resetExisting: false },
      previousTemplate.toJS()
    );
    entity.template = template.get('_id');

    dispatch(formActions.reset(form));
    setTimeout(() => {
      dispatch(formActions.load(form, _objectSpread(_objectSpread({}, entity), {}, { title })));
    });
  };
}

export function loadTemplate(form, template) {
  return (dispatch) => {
    const entity = { template: template._id, metadata: {} };
    entity.metadata = resetMetadata(entity.metadata, template, { resetExisting: true });
    dispatch(formActions.load(form, entity));
    dispatch(formActions.setPristine(form));
  };
}

export function removeIcon(model) {
  return formActions.change(model, { _id: null, type: 'Empty' });
}

export function multipleUpdate(entities, values) {
  return async (dispatch) => {
    const ids = entities.map((e) => e.get('sharedId')).toJS();
    const updatedEntities = await api.multipleUpdate(new RequestParams({ ids, values }));
    dispatch(notificationActions.notify(t('System', 'Update success', null, false), 'success'));
    if (values.published !== undefined) {
      await dispatch(unselectAllDocuments());
      dispatch(removeDocuments(updatedEntities));
    }
    return updatedEntities;
  };
}

export async function getSuggestions(templates, searchTerm = '') {
  const request = new RequestParams({ searchTerm, templates });
  return searchAPI.getSuggestions(request);
}

export const clearMetadataSelections = () =>
actions.unset('documentViewer.metadataExtraction', ['selections']);

export { resetReduxForm, resetMetadata, UnwrapMetadataObject };