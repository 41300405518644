import api from "../utils/api";
import { RequestParams } from "../utils/RequestParams";


export default {
  async save(settings) {
    return api.post('settings', settings).then((response) => response.json);
  },

  async get(query = new RequestParams()) {
    return api.get('settings', query).then((response) => response.json);
  },

  async stats(query = new RequestParams()) {
    return api.get('stats', query).then((response) => response.json);
  }
};