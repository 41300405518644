export const libraryViewInfo = {
  cards: {
    url: 'library',
    icon: 'th'
  },
  table: {
    url: 'library/table',
    icon: 'align-justify'
  },
  map: {
    url: 'library/map',
    icon: 'map-marker'
  }
};