const _excluded = ["rowId", "subRows"],_excluded2 = ["rowId", "_id"];function _objectWithoutProperties(e, t) {if (null == e) return {};var o,r,i = _objectWithoutPropertiesLoose(e, t);if (Object.getOwnPropertySymbols) {var s = Object.getOwnPropertySymbols(e);for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);}return i;}function _objectWithoutPropertiesLoose(r, e) {if (null == r) return {};var t = {};for (var n in r) if ({}.hasOwnProperty.call(r, n)) {if (e.includes(n)) continue;t[n] = r[n];}return t;}function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}

















const filterAvailableTemplates = (templates, filters) => {
  const usedTemplatesIds = [];

  filters === null || filters === void 0 ? void 0 : filters.forEach((filter) => {
    if (filter.subRows) {
      filter.subRows.forEach((item) => {
        usedTemplatesIds.push(item.id);
      });
    }

    if (filter.id) {
      usedTemplatesIds.push(filter.id);
    }
  });

  return templates.filter((template) => !usedTemplatesIds.includes(template._id));
};

const createNewFilters = (
selectedTemplatesIds,
templates) =>
{
  const newFilters = selectedTemplatesIds.map((templateId) => {
    const template = templates === null || templates === void 0 ? void 0 : templates.find((templ) => templ._id === templateId);
    return { id: templateId, name: template === null || template === void 0 ? void 0 : template.name, rowId: templateId };
  });

  return newFilters;
};

const updateFilters = (newFilter, filters) => {
  let isNewFilter = true;

  const updatedFilters = filters === null || filters === void 0 ? void 0 : filters.map((filter) => {
    if (filter.id === newFilter.id) {
      isNewFilter = false;
      return newFilter;
    }
    return filter;
  });

  if (isNewFilter) {
    return [...(updatedFilters || []), newFilter];
  }

  return updatedFilters;
};

const deleteFilters = (originalFilters, filtersToRemove) => {
  if (!filtersToRemove) {
    return originalFilters;
  }

  const updatedFilters = [];

  originalFilters === null || originalFilters === void 0 ? void 0 : originalFilters.forEach((filter) => {
    const updatedFilter = _objectSpread({}, filter);
    if (!filtersToRemove.includes(filter.rowId)) {
      if (filter.subRows) {
        const subRows = filter.subRows.filter((item) => !filtersToRemove.includes(item.rowId));
        updatedFilter.subRows = subRows;
      }
      updatedFilters.push(updatedFilter);
    }
  });

  return updatedFilters;
};

const sanitizeFilters = (filters) => {
  const sanitizedFilters = [];

  filters === null || filters === void 0 ? void 0 : filters.forEach((filter) => {
    const _filter = _objectSpread({}, filter),{ rowId, subRows } = _filter,sanitizedFilter = _objectWithoutProperties(_filter, _excluded);

    if (subRows && subRows.length === 0) {
      return;
    }

    if (subRows) {
      sanitizedFilter.items = subRows.map(
        (_ref) => {let { rowId: itemRowId, _id } = _ref,sanitizedItem = _objectWithoutProperties(_ref, _excluded2);return sanitizedItem;}
      );
    }

    sanitizedFilters.push(sanitizedFilter);
  });

  return sanitizedFilters;
};

const formatFilters = (filters) =>
filters === null || filters === void 0 ? void 0 : filters.map((filter) => {
  const tableFilter = _objectSpread(_objectSpread({},
  filter), {}, {
    rowId: filter._id });

  if (filter.items) {
    const subRows = filter.items.map((item) => _objectSpread(_objectSpread({}, item), {}, { rowId: item.id }));
    tableFilter.subRows = subRows;
  }
  return tableFilter;
});


export {
  filterAvailableTemplates,
  createNewFilters,
  updateFilters,
  deleteFilters,
  sanitizeFilters,
  formatFilters };