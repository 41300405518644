const _excluded = ["serializedFile"];function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}function _objectWithoutProperties(e, t) {if (null == e) return {};var o,r,i = _objectWithoutPropertiesLoose(e, t);if (Object.getOwnPropertySymbols) {var s = Object.getOwnPropertySymbols(e);for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);}return i;}function _objectWithoutPropertiesLoose(r, e) {if (null == r) return {};var t = {};for (var n in r) if ({}.hasOwnProperty.call(r, n)) {if (e.includes(n)) continue;t[n] = r[n];}return t;}import superagent from 'superagent';

import { groupBy } from 'lodash';

import * as attachmentsTypes from "../../Attachments/actions/actionTypes";
import * as uploadsActionTypes from "../../Uploads/actions/actionTypes";
import { ensure } from "../../../shared/tsUtils";
import { constructFile } from "../../../shared/fileUploadUtils";

const readFileAsBase64 = async (file, cb) =>
new Promise((resolve) => {
  const reader = new FileReader();

  reader.onload = (base64) => {
    const info = ensure(base64.target.result);
    cb(info);
    resolve();
  };
  reader.readAsDataURL(file);
});

const saveEntityWithFiles = async (entity, dispatch) => {
  const [attachments, supportingFiles] = entity.attachments ?
  entity.attachments.reduce(
    (accumulator, attachmentInfo) => {
      const { serializedFile } = attachmentInfo,attachment = _objectWithoutProperties(attachmentInfo, _excluded);
      accumulator[0].push(attachment);
      if (serializedFile) {
        accumulator[1].push(constructFile(attachmentInfo));
      }
      return accumulator;
    },
    [[], []]
  ) :
  [[], []];

  const { oldDocuments = [], newDocuments = [] } = groupBy(entity.documents || [], (document) =>
  document._id !== undefined ? 'oldDocuments' : 'newDocuments'
  );
  const entityToSave = _objectSpread(_objectSpread({}, entity), {}, { documents: oldDocuments });

  const addedDocuments = await Promise.all(
    newDocuments.map(async (file) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      const blob = await fetch(file.data).then(async (r) => r.blob());
      const newDocument = new File([blob], file.originalFile.name, { type: blob.type });
      URL.revokeObjectURL(file.data);
      return newDocument;
    })
  );

  return new Promise((resolve, reject) => {
    const request = superagent.
    post('/api/entities').
    set('Accept', 'application/json').
    set('X-Requested-With', 'XMLHttpRequest').
    field(
      'entity',
      JSON.stringify(_objectSpread(_objectSpread({},
      entityToSave),
      attachments.length > 0 && { attachments })
      )
    );

    if (dispatch) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      request.on('progress', (data) => {
        if (data.percent && Math.floor(data.percent) === 100) {
          return dispatch({
            type: attachmentsTypes.ATTACHMENT_LOCAL_COMPLETE,
            entity: entity.sharedId || 'NEW_ENTITY'
          });
        }

        return dispatch({
          type: attachmentsTypes.ATTACHMENT_PROGRESS,
          entity: entity.sharedId || 'NEW_ENTITY',
          progress: data.percent ? Math.floor(data.percent) : data.percent
        });
      });
    }

    supportingFiles.forEach((file, index) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      request.attach(`attachments[${index}]`, file);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      request.field(`attachments_originalname[${index}]`, file.name);
    });

    addedDocuments.forEach((file, index) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      request.attach(`documents[${index}]`, file);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      request.field(`documents_originalname[${index}]`, file.name);
    });

    request.end((err, res) => {
      if (err) return reject(err);

      if (dispatch && addedDocuments.length) {
        dispatch({
          type: uploadsActionTypes.NEW_UPLOAD_DOCUMENT,
          doc: res.body.entity.sharedId
        });
      }
      resolve(res.body);
    });
  });
};

export { readFileAsBase64, saveEntityWithFiles };