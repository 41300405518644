function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import { t } from "../../I18N";

import { propertyTypes } from "../../../shared/propertyTypes";

















const getCurrentSortOption = (sortOptions, sortOption) => {
  if (!sortOption || sortOption === 'creationDate') {
    const currentOption = sortOptions.find((option) => option.value === 'creationDate');
    return currentOption === null || currentOption === void 0 ? void 0 : currentOption.label;
  }
  const currentOption = sortOptions.find((option) => option.value === sortOption);
  return currentOption === null || currentOption === void 0 ? void 0 : currentOption.label;
};

const getPropertySortType = (selected) =>
selected.type === 'text' || selected.type === 'select' ? 'string' : 'number';

const isSortableType = (type) => {
  switch (type) {
    case propertyTypes.text:
    case propertyTypes.date:
    case propertyTypes.numeric:
    case propertyTypes.select:
      return true;
    default:
      return false;
  }
};

const isSortable = (property) =>
property.filter && (
isSortableType(property.type) || property.inherit && isSortableType(property.inherit.type));

const getSortString = (property) =>
`metadata.${property.name}${property.inherit ? '.inheritedValue' : ''}`;

const getMetadataSorts = (templates) =>
templates.toJS().reduce((sorts, template) => {
  (template.properties || []).forEach((property) => {
    if (isSortable(property) && !sorts.find((s) => s.name === property.name)) {
      sorts.push({
        label: property.label,
        name: property.name,
        value: getSortString(property),
        type: property.type,
        context: template._id
      });
    }
  });
  return sorts;
}, []);

const getCommonSorts = (search) => [
...[
{ label: 'Title', value: 'title', type: 'text', context: 'System' },
{ label: 'Date added', value: 'creationDate', type: 'number', context: 'System' },
{ label: 'Date modified', value: 'editDate', type: 'number', context: 'System' }],

...(search.searchTerm ?
[
{
  label: 'Search relevance',
  value: '_score',
  type: 'number',
  context: 'System'
}] :

[])];


const filterTemplates = (
templates,
selectedTemplates) =>

templates.filter((i) => i !== undefined && selectedTemplates.includes(i.get('_id')));



const getSortOptions = (
search,
templates) =>

[...getCommonSorts(search), ...getMetadataSorts(templates)].map((option) => _objectSpread(_objectSpread({},
option), {}, {
  label: t(option.context, option.label, undefined, false) })
);



export { getPropertySortType, getCurrentSortOption, filterTemplates, getSortOptions };