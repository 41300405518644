const handledErrors = {
  400: {
    name: 'Bad Request',
    message: 'The request could not be processed.',
    status: 400
  },
  404: {
    name: 'Not Found',
    message: "We can't find the page you're looking for.",
    status: 404
  },
  500: {
    name: 'Unexpected error',
    message: 'Something went wrong',
    status: 500
  }
};












export { handledErrors };