import { actions } from "../../BasicReducer";
import { store } from "../../store";







const updatePageDatasets = (name, data) => {var _store$getState$page$;
  if (!store) {
    return;
  }

  const currentDatasets = (_store$getState$page$ = store.getState().page.datasets) === null || _store$getState$page$ === void 0 ? void 0 : _store$getState$page$.toJS();

  if (currentDatasets && currentDatasets[name]) {
    currentDatasets[name] = data;
    store.dispatch(actions.set('page/datasets', currentDatasets));
  }
};

export { updatePageDatasets };