
import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";











const get = async (
searchParams,
headers) =>
{
  try {
    const requestParams = new RequestParams(searchParams, headers);
    const response = await api.get('activityLog', requestParams);
    return response.json;
  } catch (e) {
    return e;
  }
};


export { get };