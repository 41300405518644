export const PARSE_RELATIONSHIPS_RESULTS = 'PARSE_RELATIONSHIPS_RESULTS';
export const ADD_RELATIONSHIPS_HUB = 'ADD_RELATIONSHIPS_HUB';
export const UPDATE_RELATIONSHIPS_LEFT_TYPE = 'UPDATE_RELATIONSHIPS_LEFT_TYPE';
export const UPDATE_RELATIONSHIPS_RIGHT_TYPE = 'UPDATE_RELATIONSHIPS_RIGHT_TYPE';
export const TOGGLE_REMOVE_RELATIONSHIPS_LEFT = 'TOGGLE_REMOVE_RELATIONSHIPS_LEFT';
export const TOGGLE_REMOVE_RELATIONSHIPS_RIGHT_GROUP = 'TOGGLE_REMOVE_RELATIONSHIPS_RIGHT_GROUP';
export const SET_RELATIONSHIPS_ADD_TO_DATA = 'EDIT_RELATIONSHIPS_GROUP';
export const ADD_RELATIONSHIPS_ENTITY = 'ADD_RELATIONSHIPS_ENTITY';
export const TOGGLE_REMOVE_RELATIONSHIPS_ENTITY = 'TOGGLE_REMOVE_RELATIONSHIPS_ENTITY';
export const TOGGLE_MOVE_RELATIONSHIPS_ENTITY = 'TOGGLE_MOVE_RELATIONSHIPS_ENTITY';
export const MOVE_RELATIONSHIPS_ENTITY = 'MOVE_RELATIONSHIPS_ENTITY';
export const UPDATE_RELATIONSHIP_ENTITY_DATA = 'UPDATE_RELATIONSHIP_ENTITY_DATA';

export const EDIT_RELATIONSHIPS = 'EDIT_RELATIONSHIPS';
export const SAVING_RELATIONSHIPS = 'SAVING_RELATIONSHIPS';
export const SAVED_RELATIONSHIPS = 'SAVED_RELATIONSHIPS';

//UI
export const OPEN_RELATIONSHIPS_PANEL = 'OPEN_RELATIONSHIPS_PANEL';
export const CLOSE_RELATIONSHIPS_PANEL = 'CLOSE_RELATIONSHIPS_PANEL';
export const SEARCHING_RELATIONSHIPS = 'SEARCHING_RELATIONSHIPS';