export const SET_UPLOADS = 'SET_UPLOADS';
export const SET_THESAURIS_UPLOADS = 'SET_THESAURIS_UPLOADS';
export const SET_TEMPLATES_UPLOADS = 'SET_TEMPLATES_UPLOADS';
export const NEW_UPLOAD_DOCUMENT = 'NEW_UPLOAD_DOCUMENT';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE';
export const UPLOADS_COMPLETE = 'UPLOADS_COMPLETE';
export const BATCH_UPLOAD_COMPLETE = 'BATCH_UPLOAD_COMPLETE';
export const CONVERSION_COMPLETE = 'CONVERSION_COMPLETE';
export const UPDATE_DOCUMENT = 'UPLOADS/UPDATE_DOCUMENT';
export const UPDATE_DOCUMENTS = 'UPLOADS/UPDATE_DOCUMENTS';
export const ENTER_UPLOADS_SECTION = 'ENTER_UPLOADS_SECTION';
export const MOVED_TO_LIBRARY = 'MOVED_TO_LIBRARY';
export const ELEMENT_DELETED = 'ELEMENT_DELETED';
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const ELEMENT_CREATED = 'ELEMENT_CREATED';
export const SELECT_DOCUMENT = 'UPLOADS/SELECT_DOCUMENT';
export const SELECT_DOCUMENTS = 'UPLOADS/SELECT_DOCUMENTS';
export const UNSELECT_DOCUMENT = 'UPLOADS/UNSELECT_DOCUMENT';
export const UNSELECT_ALL_DOCUMENTS = 'UPLOADS/UNSELECT_ALL_DOCUMENTS';
export const UPDATE_SELECTED_ENTITIES = 'UPLOADS/UPDATE_SELECTED_ENTITIES';