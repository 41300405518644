function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);} /** @format */

import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/*
StateSelector allows making sub-components state-dependent without
adding that state to the parent's props, thus reducing the re-rendering of
the parent.

Usage:
class Parent extends Component {
  render() {
    return (
      <div>
        <Static Parts ... />
        <StateSelector myVal={createSelector(state => state.path.to.obj, value => value)}>
          {({ myVal }) => (<Dynamic Parts propVal={myVal} ... />)}
        </StateSelector>
        <More Static Parts ... />
      </div>
    );
  }
}
*/
export class StateSelectorBase extends Component {
  render() {
    return this.props.children(this.props);
  }
}

StateSelectorBase.defaultProps = {
  isPristine: undefined
};







export const StateSelector = connect((state, ownProps) =>
Object.keys(ownProps).
filter((k) => !['children'].includes(k)).
reduce((res, k) => _objectSpread(_objectSpread({}, res), {}, { [k]: ownProps[k](state) }), {})
)(StateSelectorBase);