import { DateTime } from 'luxon';

const secondsToDate = (timestamp, locale) =>
DateTime.fromSeconds(Number(timestamp)).
setZone('UTC').
toLocaleString(DateTime.DATE_FULL, { locale: locale || 'en' });

const secondsToISODate = (timestamp) =>
DateTime.fromSeconds(Number(timestamp)).setZone('UTC').toISODate();

export { secondsToDate, secondsToISODate };