

import api from "../../../utils/api";
import { RequestParams } from "../../../utils/RequestParams";

const get = async (headers) => {
  const requestParams = new RequestParams({}, headers);
  return api.get('relationtypes', requestParams).then((response) => response.json.rows);
};

const save = async (relationshipType) => {
  const requestParams = new RequestParams(relationshipType);
  return api.post('relationtypes', requestParams).then((response) => response.json);
};

const deleteRelationtypes = async (ids) => {
  const allDeleted = ids.map(async (id) => {
    const requestParams = new RequestParams({ _id: id });
    return api.delete('relationtypes', requestParams).then((response) => response.json);
  });

  return Promise.all(allDeleted);
};

const relationshipTypeBeingUsed = async (relationtypeId) => {
  const requestParams = new RequestParams({ relationtypeId });
  return api.
  get('references/count_by_relationtype', requestParams).
  then((response) => response.json > 0);
};

export { get, save, deleteRelationtypes, relationshipTypeBeingUsed };