function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import { actions } from "../../BasicReducer";
import { actions as formActions } from 'react-redux-form';
import { notificationActions } from "../../Notifications";
import referencesAPI from "../../Viewer/referencesAPI";
import { fromJS as Immutable } from 'immutable';
import prioritySortingCriteria from "../../utils/prioritySortingCriteria";
import { RequestParams } from "../../utils/RequestParams";

export function search(requestParams) {
  const { sharedId, sort, filters } = requestParams.data;
  const searchTerm =
  requestParams.data.search && requestParams.data.search.searchTerm ?
  requestParams.data.search.searchTerm.value :
  '';

  let options = _objectSpread({ sharedId }, sort);
  if (filters) {
    options = _objectSpread(_objectSpread(_objectSpread({ sharedId }, sort), filters.toJS()), {}, { searchTerm });
  }
  return referencesAPI.search(requestParams.onlyHeaders().add(options));
}

export function searchReferences() {
  return async (dispatch, getState) => {
    const relationshipsList = getState().relationships.list;
    const results = await search(new RequestParams(relationshipsList));
    dispatch(actions.set('relationships/list/searchResults', results));
  };
}

export function connectionsChanged(sharedId) {
  return (dispatch, getState) => {
    dispatch(actions.set('relationships/list/filters', { limit: 10 }));
    const relationshipsList = getState().relationships.list;
    let innerSharedId = sharedId;
    if (!innerSharedId) {
      innerSharedId = relationshipsList.sharedId;
    }
    return referencesAPI.
    getGroupedByConnection(new RequestParams({ sharedId: innerSharedId })).
    then((connectionsGroups) => {
      const filteredTemplates = connectionsGroups.reduce(
        (templateIds, group) => templateIds.concat(group.templates.map((t) => t._id.toString())),
        []
      );

      const sortOptions = prioritySortingCriteria.get({
        currentCriteria: relationshipsList.sort,
        filteredTemplates,
        templates: getState().templates
      });
      return Promise.all([connectionsGroups, sortOptions]);
    }).
    then(([connectionsGroups, sort]) => {
      dispatch(actions.set('relationships/list/connectionsGroups', connectionsGroups));
      dispatch(actions.set('relationships/list/sharedId', sharedId));
      dispatch(formActions.merge('relationships/list.sort', sort));
      return searchReferences()(dispatch, getState);
    });
  };
}

export function deleteConnection(connection) {
  return async (dispatch, getState) => {
    await referencesAPI.delete(new RequestParams({ _id: connection._id }));
    dispatch(notificationActions.notify('Connection deleted', 'success'));
    return connectionsChanged()(dispatch, getState);
  };
}

export function loadAllReferences() {
  return async (dispatch, getState) => {
    const relationshipsList = getState().relationships.list;
    dispatch(
      actions.set('relationships/list/filters', relationshipsList.filters.set('limit', 9999))
    );
    return searchReferences()(dispatch, getState);
  };
}

export function loadMoreReferences(limit) {
  return function (dispatch, getState) {
    const relationshipsList = getState().relationships.list;
    dispatch(
      actions.set('relationships/list/filters', relationshipsList.filters.set('limit', limit))
    );
    return searchReferences()(dispatch, getState);
  };
}

export function setFilter(groupFilterValues) {
  return function (dispatch, getState) {
    const relationshipsList = getState().relationships.list;
    const currentFilter = relationshipsList.filters.get('filter') || Immutable({});
    const newFilter = currentFilter.merge(groupFilterValues);
    dispatch(
      actions.set('relationships/list/filters', relationshipsList.filters.set('filter', newFilter))
    );
    return searchReferences()(dispatch, getState);
  };
}

export function resetSearch() {
  return function (dispatch, getState) {
    dispatch(formActions.change('relationships/list/search.searchTerm', ''));
    dispatch(actions.set('relationships/list/filters', Immutable({})));
    return searchReferences()(dispatch, getState);
  };
}

export function switchView(type) {
  return function (dispatch, getState) {
    dispatch(actions.set('relationships/list/view', type));
    if (type === 'graph') {
      return loadAllReferences()(dispatch, getState);
    }
  };
}