


export const isSerializedFile = (file) =>
file.serializedFile !== undefined;

export const constructFile = ({ serializedFile: base64, originalname }) => {
  const fileParts = base64.split(',');
  const fileFormat = fileParts[0].split(';')[0].split(':')[1];
  const fileContent = fileParts[1];
  const buff = Buffer.from(fileContent, 'base64');

  return new File([buff], originalname || '', { type: fileFormat });
};

export const prepareHTMLMediaView = (supportingFile) => {
  const file = constructFile(supportingFile);
  return URL.createObjectURL(file);
};