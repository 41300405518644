const _excluded = ["localID"],_excluded2 = ["localID", "inserting"];function _objectWithoutProperties(e, t) {if (null == e) return {};var o,r,i = _objectWithoutPropertiesLoose(e, t);if (Object.getOwnPropertySymbols) {var s = Object.getOwnPropertySymbols(e);for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);}return i;}function _objectWithoutPropertiesLoose(r, e) {if (null == r) return {};var t = {};for (var n in r) if ({}.hasOwnProperty.call(r, n)) {if (e.includes(n)) continue;t[n] = r[n];}return t;}function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}import { actions as formActions } from 'react-redux-form';
import { RequestParams } from "../../utils/RequestParams";

import * as types from "./actionTypes";
import { notificationActions } from "../../Notifications";
import api from "../TemplatesAPI";
import ID from "../../../shared/uniqueID";
import { actions } from "../../BasicReducer";
import entitiesApi from "../../Entities/EntitiesAPI";
import { t, I18NApi } from "../../I18N";
import templateCommonProperties from "../utils/templateCommonProperties";

export const prepareTemplate = (template) => {
  const commonPropertiesExists = template.commonProperties && template.commonProperties.length;

  const commonProperties = commonPropertiesExists ?
  template.commonProperties :
  templateCommonProperties.get();

  return _objectSpread(_objectSpread({},
  template), {}, {
    commonProperties: commonProperties.map((p) => _objectSpread(_objectSpread({}, p), {}, { localID: ID() })),
    properties: template.properties.map((p) => _objectSpread(_objectSpread({}, p), {}, { localID: ID() })) });

};

export function resetTemplate() {
  return (dispatch) => {
    dispatch(formActions.reset('template.data'));
    dispatch(formActions.setInitial('template.data'));
  };
}

export function setPropertyDefaults(getState, property) {
  const propertyWithDefaults = property;
  propertyWithDefaults.localID = ID();
  if (property.type === 'select' || property.type === 'multiselect') {
    propertyWithDefaults.content = getState().thesauris.get(0).get('_id');
  }

  if (property.type === 'relationship') {
    propertyWithDefaults.inherit = false;
    propertyWithDefaults.content = '';
  }

  if (property.type === 'nested') {
    propertyWithDefaults.nestedProperties = [{ key: '', label: '' }];
  }
  return propertyWithDefaults;
}

export function addProperty(property = {}, _index = undefined) {
  return (dispatch, getState) => {
    const properties = getState().template.data.properties.slice(0);
    const index = _index !== undefined ? _index : properties.length;
    const propertyWithDefaults = setPropertyDefaults(getState, property);
    properties.splice(index, 0, propertyWithDefaults);
    dispatch(formActions.change('template.data.properties', properties));
  };
}

export function setNestedProperties(propertyIndex, properties) {
  return (dispatch) => {
    dispatch(
      formActions.load(`template.data.properties[${propertyIndex}].nestedProperties`, properties)
    );
  };
}

export function updateProperty(property, index) {
  return (dispatch, getState) => {
    const properties = getState().template.data.properties.slice(0);
    properties.splice(index, 1, property);
    dispatch(formActions.change('template.data.properties', properties));
  };
}

export function inserted(index) {
  return (dispatch) => {
    dispatch(formActions.change(`template.data.properties[${index}].inserting`, null));
  };
}

export function selectProperty(index) {
  return {
    type: types.SELECT_PROPERTY,
    index
  };
}

export function removeProperty(index) {
  return (dispatch) => {
    dispatch(formActions.remove('template.data.properties', index));
    dispatch(formActions.resetErrors('template.data'));
  };
}

export function reorderProperty(originIndex, targetIndex) {
  return (dispatch) => {
    dispatch(formActions.move('template.data.properties', originIndex, targetIndex));
  };
}

export const sanitize = (data) => {
  const commonProperties = data.commonProperties.map((prop) => {
    const { localID } = prop,sanitizedProp = _objectWithoutProperties(prop, _excluded);
    return sanitizedProp;
  });
  const properties = data.properties.map((prop) => {
    const { localID, inserting } = prop,sanitizedProp = _objectWithoutProperties(prop, _excluded2);
    if (sanitizedProp.inherit && !sanitizedProp.content) {
      sanitizedProp.inherit = false;
    }
    return sanitizedProp;
  });
  return _objectSpread(_objectSpread({}, data), {}, { properties, commonProperties });
};

export function validateMapping(template) {
  return api.validateMapping(new RequestParams(template));
}

export function saveTemplate(data) {
  let template = sanitize(data);
  return async (dispatch) => {
    dispatch({ type: types.SAVING_TEMPLATE });
    try {
      const response = await api.save(new RequestParams(template));
      template = prepareTemplate(response);
      dispatch({ type: types.TEMPLATE_SAVED, data: template });
      dispatch(actions.update('templates', template));
      dispatch(formActions.merge('template.data', template));
      dispatch(
        notificationActions.notify(t('System', 'Saved successfully.', null, false), 'success')
      );
    } catch (e) {
      dispatch({ type: types.TEMPLATE_SAVED, data });
      throw e;
    } finally {
      // Re-load translations
      const translations = await I18NApi.get();
      dispatch(actions.set('translations', translations));
    }
  };
}

export function updateValue(model, value) {
  return (dispatch) => {
    dispatch(formActions.change(`template.data${model}`, value));
  };
}

export function countByTemplate(template) {
  return entitiesApi.countByTemplate(new RequestParams({ templateId: template._id }));
}