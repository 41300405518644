export const SET_REFERENCES = 'SET_REFERENCES';
export const VIEWER_SET_TEMPLATES = 'VIEWER_SET_TEMPLATES';
export const VIEWER_SET_THESAURIS = 'VIEWER_SET_THESAURIS';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_TARGET_DOCUMENT = 'SET_TARGET_DOCUMENT';
export const RESET_DOCUMENT_VIEWER = 'RESET_DOCUMENT_VIEWER';

export const SET_SELECTION = 'SET_SELECTION';
export const UNSET_SELECTION = 'UNSET_SELECTION';
export const SET_TARGET_SELECTION = 'SET_TARGET_SELECTION';
export const UNSET_TARGET_SELECTION = 'UNSET_TARGET_SELECTION';
export const VIEWER_UPDATE_DOCUMENT = 'VEWER_UPDATE_DOCUMENT';

//REFERENCES
export const ADD_REFERENCE = 'ADD_CREATED_REFERENCE';
export const REMOVE_REFERENCE = 'REMOVE_REFERENCE';
export const TOGGLE_REFERENCES = 'TOGGLE_REFERENCES';

//MENU
export const LOAD_DEFAULT_VIEWER_MENU = 'LOAD_DEFAULT_VIEWER_MENU';

//UI
export const GO_TO_ACTIVE = 'GO_TO_ACTIVE';
export const OPEN_PANEL = 'OPEN_PANEL';
export const PDF_READY = 'PDF_READY';
export const TARGET_PDF_READY = 'TARGET_PDF_READY';
export const SHOW_TAB = 'SHOW_TAB';
export const CLOSE_PANEL = 'CLOSE_PANEL';
export const SELECT_TARGET_DOCUMENT = 'SELECT_TARGET_DOCUMENT';
export const RESET_REFERENCE_CREATION = 'RESET_REFERENCE_CREATION';
export const HIGHLIGHT_REFERENCE = 'HIGHLIGHT_REFERENCE';
export const ACTIVE_REFERENCE = 'ACTIVE_REFERENCE';
export const ACTIVATE_MULTIPLE_REFERENCES = 'ACTIVATE_MULTIPLE_REFERENCES';
export const DEACTIVATE_REFERENCE = 'DEACTIVATE_REFERENCE';
export const SELECT_SNIPPET = 'SELECT_SNIPPET';
export const SHOW_TEXT_SELECTION_MENU = 'SHOW_TEXT_SELECTION_MENU';