function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}function sameProperty(prop1, prop2) {var _prop1$inherit, _prop2$inherit;
  return (
    prop1.name === prop2.name &&
    prop1.type === prop2.type &&
    prop1.content === prop2.content &&
    ((_prop1$inherit = prop1.inherit) === null || _prop1$inherit === void 0 ? void 0 : _prop1$inherit.property) === ((_prop2$inherit = prop2.inherit) === null || _prop2$inherit === void 0 ? void 0 : _prop2$inherit.property));

}

function isOrInheritsSelect(property) {var _property$inherit, _property$inherit2;
  return (
    property.type === 'select' ||
    property.type === 'multiselect' ||
    ((_property$inherit = property.inherit) === null || _property$inherit === void 0 ? void 0 : _property$inherit.type) === 'select' ||
    ((_property$inherit2 = property.inherit) === null || _property$inherit2 === void 0 ? void 0 : _property$inherit2.type) === 'multiselect');

}

function templateHasProperty(template, property) {
  return template.properties.filter((prop) => sameProperty(prop, property)).length;
}

function allTemplatesHaveIt(templates, property) {
  return templates.reduce(
    (allHaveIt, template) => allHaveIt && templateHasProperty(template, property),
    true
  );
}

const comonProperties = (templates, documentTypes = [], excludedTypes = []) => {
  const properties = [];
  const selectedTemplates = templates.filter((template) =>
  documentTypes.includes(template._id.toString())
  );

  if (selectedTemplates.length) {
    const propertiesToCompare = selectedTemplates[0].properties.filter(
      (property) => !excludedTypes.includes(property.type)
    );
    propertiesToCompare.forEach((_property) => {
      if (allTemplatesHaveIt(selectedTemplates, _property)) {
        const property = selectedTemplates.reduce((result, tmpl) => {
          const prop = tmpl.properties.find((_prop) => sameProperty(_prop, _property), {});
          return prop.required ? prop : result;
        }, _property);
        properties.push(_objectSpread({}, property));
      }
    });
  }

  return properties;
};

function comonFilters(templates, documentTypes = [], forcedProps = []) {
  return comonProperties(templates, documentTypes).filter(
    (prop) => prop.filter || forcedProps.includes(prop.name)
  );
}

function defaultFilters(templates, forcedProps = []) {
  return templates.reduce((filters, template) => {
    template.properties.forEach((prop) => {
      if (
      (forcedProps.includes(prop.name) || prop.filter && prop.defaultfilter) &&
      !filters.find((_prop) => sameProperty(prop, _prop)))
      {
        filters.push(prop);
      }
    });
    return filters;
  }, []);
}

const allUniqueProperties = (templates) =>
templates.reduce((filters, template) => {
  template.properties.forEach((prop) => {
    if (!filters.find((_prop) => sameProperty(prop, _prop))) {
      filters.push(prop);
    }
  });
  return filters;
}, []);

const allProperties = (templates) => templates.map((template) => template.properties || []).flat();

const getInheritedProperty = (property, properties) => {
  if (!Array.isArray(properties)) {
    return properties[property.inherit.property.toString()];
  }
  return properties.find((p) => property.inherit.property.toString() === p._id.toString());
};

const textFields = (templates) =>
allUniqueProperties(templates).filter(
  (property) => property.type === 'text' || property.type === 'markdown'
);

export default {
  allProperties,
  getInheritedProperty,
  comonProperties,
  comonFilters,
  defaultFilters,
  allUniqueProperties,
  textFields,
  isOrInheritsSelect
};