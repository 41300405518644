import mimetypes from 'mime-types';

export const mimeTypeFromUrl = (url) => {var _url$split$0$split$po;
  const acceptedMimeTypes = ['image', 'audio', 'text', 'video'];
  const extension = url === null || url === void 0 ? void 0 : (_url$split$0$split$po = url.split(/[#?]/)[0].split('.').pop()) === null || _url$split$0$split$po === void 0 ? void 0 : _url$split$0$split$po.trim();

  if (extension) {
    const mimetype = mimetypes.lookup(extension) || 'text/html';
    if (acceptedMimeTypes.includes(mimetype.split('/')[0])) {
      return mimetype;
    }
  }

  return 'text/html';
};